
import {
    reactive
} from "vue";
import validate from 'ele-admin-pro/packages/validate';

export default {
    emits:['update:visible'],
    props: {
        formState: {
            type: Object,
            default: () => {},
        },
        visible: Boolean,
    },

    data() {
        return {
            loading: false,
            imgFile: "",
            fileList: [], // 文件列表
            previewImage: "", // 预览路径
            previewVisible: false, // 开启预览
            // 表单规则
            rules: {
                user_account_code: [{
                    required: true,
                    message: this.$t("user.tips.user_account_code_input"),
                    trigger: "blur",
                }],
                name: [{
                    required: true,
                    message: this.$t("user.tips.user_account_code_input"),
                    trigger: "blur",
                }],
                bank_branch: [{
                    required: true,
                    message: this.$t("user_withdraw.tips.bank_branch"),
                    trigger: "blur",
                }],
                bank_bccount: [{
                    required: true,
                    message: this.$t("user_withdraw.tips.bank_bccount"),
                    trigger: "blur",
                }],
                value: [{
                    required: true,
                    message: this.$t("user.tips.charge_value_input"),
                    trigger: "blur",
                }, {
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.reject(this.$t("user.tips.charge_value_input"));
                        } else if (!validate.isNumber(value)) {
                            return Promise.reject(this.$t("user_withdraw.tips.tips_isNumber"));
                        } else {
                            return Promise.resolve();
                        }
                    },
                }],
            },
        };
    },
    computed: {
        // 表单信息重新计算表
        computedFormState: {
            get() {
                const obj = reactive(Object.assign({}, this.formState));
                return obj;
            },
            set(val) {
                this.$emit("update:formState", val);
            },
        },
        // 重构文件列表
        getFileList: {
            get() {
                return this.fileList;
            },
            set(val) {
                this.fileList = val.slice(val.length - 1);
            },
        },
    },
    methods: {
        // 取消预览
        handleCancel() {
            this.previewVisible = false;
        },
        // 处理预览
        handlePreview(file) {
            this.previewVisible = true;
            this.previewImage = file.thumbUrl;
        },
        // 提交表单
        handleSubmit() {
            this.$refs.formRef
                .validate()
                .then(() => {
                    this.$http
                        .post(`/user/withdraw/Create`, {
                            ...this.computedFormState,
                        })
                        .then((res) => {
                            if (res.status == 200 && res.data.code == 0) {
                                this.$message.success("share.success");
                                this.$emit("update:visible", false);
                                this.$emit("reload");
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        // 上传附件
        handleUpload(resFile) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", resFile.file);

            this.imgFile = resFile.file;
            this.$http
                .post("/admin/deposit/upload_attachment", formData)
                .then((response) => {
                    if (response.status == 200 && response.data.code == 0) {
                        const res = response.data;
                        this.computedFormState.attachment = res.data;
                        this.$message.success("share.success");
                        resFile.onSuccess(res.data, resFile.file);
                    }
                })
                .catch(() => {
                    resFile.onError();
                    this.$message.error("share.error");
                    this.fileList = [];
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
